import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { PermissionsGuard } from '@zonar-ui/auth';
import { ErrorsComponent } from './components/errors/errors.component';
import { ManagePassholdersComponent } from './views/manage-passholders/manage-passholders.component';
import { EventsContainerComponent } from './views/events/events-container/events-container.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'manage',
    pathMatch: 'full'
  },
  // {
  //   path: '**',
  //   redirectTo: '<TODO make this redirect to a not found component>'
  // },
  {
    path: 'manage',
    component: ManagePassholdersComponent,
    canActivate: [AuthGuard, PermissionsGuard]
  },
  {
    path: 'events',
    component: EventsContainerComponent,
    canActivate: [AuthGuard, PermissionsGuard]
  },
  { path: 'unauthorized', component: ErrorsComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}

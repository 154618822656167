import {
  Component,
  OnDestroy,
  OnInit,
  Input,
  ViewEncapsulation
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { BehaviorSubject, Subject } from 'rxjs';
import { EventsTableDataSource } from './events-table.datasource';
import { ZonarUITableModule } from '@zonar-ui/table';
import { GetEnvironmentService } from '@src/app/services/get-environment/get-environment.service';
import { takeUntil } from 'rxjs/operators';
import { ZpxTableComponent } from '@src/app/components/zpx-table/zpx-table.component';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-passholder-events-table',
  standalone: true,
  providers: [EventsTableDataSource],
  imports: [CommonModule, ZonarUITableModule, ZpxTableComponent, MatIconModule],
  templateUrl: './events-table.component.html',
  styleUrls: ['./events-table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EventsTableComponent implements OnInit, OnDestroy {
  constructor(
    private eventsDatasSource: EventsTableDataSource,
    private getEnvService: GetEnvironmentService
  ) {}

  @Input() showCheckmarks = false;
  private onDestroy$ = new Subject<void>();
  columns$ = new BehaviorSubject([]);
  dataSource = this.eventsDatasSource;
  offset = 0;
  currentPage: number = 1;

  pageSize =
    this.getEnvService.getEnvironmentProperty('paginationSettings')['pageSize'];

  totalResults: number = 0;

  @Input() enableMobileTable = true;
  @Input() showTableHeader = true;

  ngOnInit(): void {
    const standardColumns = this.dataSource.getStandardColumns(
      this.showCheckmarks
    );
    this.columns$.next(standardColumns);
    this.dataSource
      .populateEventsTable()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe();
  }
  ngOnDestroy(): void {
    this.dataSource.onTableDestroy();
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.columns$.complete();
  }
}

import { DropdownOption } from '@zonar-ui/searchable-dropdown';
import * as dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc, { parseToLocal: true });

export const isLocalDevEnv = () => {
  // in order for unit tests (ran from localhost:9876) to pass, they need to be excluded from 'local'
  return (
    window?.location?.origin?.includes('localhost:4200') ||
    window?.location?.origin?.includes('local.dev')
  );
};

// title and string args should be two keys from the objects of the passed in array
export const makeDropdownOptions = (
  arr: any[],
  title: string,
  value: string
): DropdownOption[] => {
  return arr
    .map((item) => {
      return {
        title: item[title],
        value: item[value]
      };
    })
    .sort((a, b) => {
      const _a = a.title.toLowerCase();
      const _b = b.title.toLowerCase();
      if (_a < _b) {
        return -1;
      }
      if (_a > _b) {
        return 1;
      }
      return 0;
    });
};

export const getMostRecentPass = (
  passes: { number: string; active: boolean; insert_ts: Date }[]
): { number: string; active: boolean; insert_ts: Date } => {
  if (passes?.length) {
    return passes
      .slice()
      .sort((a, b) => b.insert_ts.getTime() - a.insert_ts.getTime())[0];
  }

  return null;
};

// look up dayjs string formats at https://day.js.org/docs/en/parse/string-format
export const formatDate = (date: Date, format = 'YYYY-MM-DD') => {
  return dayjs(date).utc().format(format);
};

export const downloadCsv = (csvContent: string, fileName: string) => {
  const blob = new Blob([csvContent], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
};

import { Injectable } from '@angular/core';
import { LEGACY_TYPES, LegacyLocation } from '@src/app/models/entity-api.model';
import { PermissionsService } from '@zonar-ui/auth';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AppService } from '@src/app/app.service';

@Injectable({
  providedIn: 'root'
})
export class GetDivisionsService {
  constructor(
    private permissionsService: PermissionsService,
    private appService: AppService
  ) {}

  getDivisions(): Observable<{ name: string; id: string }[]> {
    return this.permissionsService.getDivisionMap().pipe(
      filter((divMap) => divMap !== null),
      map((divMap) => {
        let divisionIds: any[] = Object.keys(divMap);
        const legacyLocations = [];
        const divisions = divisionIds
          .map((d) => {
            return {
              name: divMap[d].name,
              id: d
            };
          })
          .filter((d) => {
            if (divMap[d.id].type === LEGACY_TYPES.LEGACY_LOCATION) {
              legacyLocations.push(divMap[d.id]);
            }
            return divMap[d.id].type !== LEGACY_TYPES.LEGACY_LOCATION;
          })
          .sort((a, b) => {
            const _a = a?.name?.toLowerCase();
            const _b = b?.name?.toLowerCase();
            if (_a < _b) {
              return -1;
            }
            if (_a > _b) {
              return 1;
            }
            return 0;
          });
        this.appService.divisions$.next(divisions);

        if (divisions.length === 1) {
          this.appService.selectedDivisionId$.next(divisions[0].id);
        }
        this.appService.legacyLocations$.next(
          legacyLocations.sort((a, b) => {
            const _a = a?.name?.toLowerCase();
            const _b = b?.name?.toLowerCase();
            if (_a < _b) {
              return -1;
            }
            if (_a > _b) {
              return 1;
            }
            return 0;
          }) as LegacyLocation[]
        );
        return divisions;
      })
    );
  }

  isSingleDivisionUser(): Observable<boolean> {
    return this.appService.divisions$.pipe(
      map((divisions) => {
        return divisions?.length === 1;
      })
    );
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventsTableComponent } from '../events-table/events-table.component';
import { FlexModule, FlexLayoutModule } from '@angular/flex-layout';
import { EventsFilterBarComponent } from '../events-filter-bar/events-filter-bar.component';
import { DeviceDetectionService } from '@src/app/services/device-detection/device-detection.service';
import { ZpxApiService } from '@src/app/services/zpx-api-service/zpx-api.service';
import { first, takeUntil } from 'rxjs/operators';
import { GetAssetsService } from '@src/app/services/get-assets/get-assets.service';
import { Subject } from 'rxjs';
import { AppService } from '@src/app/app.service';
@Component({
  selector: 'app-events-container',
  standalone: true,
  imports: [
    CommonModule,
    EventsTableComponent,
    FlexModule,
    FlexLayoutModule,
    EventsFilterBarComponent
  ],
  templateUrl: './events-container.component.html',
  styleUrls: ['./events-container.component.scss']
})
export class EventsContainerComponent implements OnInit, OnDestroy {
  constructor(
    public device: DeviceDetectionService,
    private zpxService: ZpxApiService,
    private assetNamesService: GetAssetsService,
    private appService: AppService
  ) {}

  private onDestroy$ = new Subject<void>();

  ngOnInit(): void {
    this.zpxService.getPassholderTypes().pipe(first()).subscribe();
    this.assetNamesService
      .getAssets()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((assets) => {
        this.appService.assets$.next(assets);
      });
  }

  toggleFilterBarDisplay = true;

  mobileViewClasses = {
    'mobile-view': this.device.isMobile ? true : false,
    hidden: false
  };

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
  mobileDisplayHandler(filtersDisplayed: boolean) {
    this.mobileViewClasses.hidden = filtersDisplayed ? true : false;
  }

  onMobileFilterToggled(event): void {
    this.toggleFilterBarDisplay = !this.toggleFilterBarDisplay;
  }
}

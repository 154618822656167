import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  of,
  throwError
} from 'rxjs';
import { CollectionViewer } from '@angular/cdk/collections';
import {
  ZonarUITableDataSource,
  ZonarUITableModel,
  ZonarUITableCellType
} from '@zonar-ui/table';
import { ZpxApiService } from '@src/app/services/zpx-api-service/zpx-api.service';
import {
  PassholderForTable,
  PASSHOLDER_COLUMN_HEADERS,
  STATUSES,
  ZpxApiPassholderParams,
  Passholder,
  PassholdersReportHttpResponseBody,
  CustomTypeColumn,
  ZpxFrontendPageParams,
  PASSHOLDER_TYPES
} from '@src/app/models/zpx-api.model';
import { GetEnvironmentService } from '@src/app/services/get-environment/get-environment.service';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  tap
} from 'rxjs/operators';
import { AppService } from '@src/app/app.service';
import { GetDivisionsService } from '@src/app/services/get-divisions/get-divisions.service';
import { TablePassholdersFilterBarService } from '../table-passholders-filter-bar/table-passholders-filter-bar.service';
import {
  getMostRecentPass,
  formatDate
} from '@src/app/shared/utilities/utilities';
import _ from 'lodash';

@Injectable()
export class PassholderTableDataSource implements ZonarUITableDataSource {
  constructor(
    private zpxApiService: ZpxApiService,
    private getEnvService: GetEnvironmentService,
    private appService: AppService,
    private divisionsService: GetDivisionsService,
    private filterBarService: TablePassholdersFilterBarService
  ) {}

  private tableLoading = new BehaviorSubject<boolean>(undefined);
  private errorMessage = new BehaviorSubject<string>('');
  private totalResults = new BehaviorSubject<number>(0);
  private tableDataSubject$ = new BehaviorSubject<any[]>([]);
  private paginationParamsSubject = new BehaviorSubject<any>({});
  private standardColumns: ZonarUITableModel[] = [
    {
      columnDef: 'checkmark',
      header: null,
      sortable: false,
      type: ZonarUITableCellType.Checkbox,
      cellType: ZonarUITableCellType.Checkbox,
      checkboxDisabled: () => false,
      cell: (p) => p
    },
    {
      columnDef: 'card_number',
      header: PASSHOLDER_COLUMN_HEADERS.CARD_NUMBER,
      sortable: false,
      cellType: ZonarUITableCellType.Text,
      cell: (p: PassholderForTable) => p.card_number
    },
    {
      columnDef: 'status',
      header: PASSHOLDER_COLUMN_HEADERS.STATUS,
      sortable: false,
      cellType: ZonarUITableCellType.Text,
      cell: (p: PassholderForTable) => p.status
    },
    {
      columnDef: 'last_name',
      header: PASSHOLDER_COLUMN_HEADERS.LAST_NAME,
      sortable: false,
      cellType: ZonarUITableCellType.Text,
      cell: (p: PassholderForTable) => p.last_name
    },
    {
      columnDef: 'first_name',
      header: PASSHOLDER_COLUMN_HEADERS.FIRST_NAME,
      sortable: false,
      cellType: ZonarUITableCellType.Text,
      cell: (p: PassholderForTable) => p.first_name
    },
    {
      columnDef: 'unique_id',
      header: PASSHOLDER_COLUMN_HEADERS.UNIQUE_ID,
      sortable: false,
      cellType: ZonarUITableCellType.Text,
      cell: (p: PassholderForTable) => p.unique_id
    },
    {
      columnDef: 'group_name',
      header: PASSHOLDER_COLUMN_HEADERS.GROUP_NAME,
      sortable: false,
      cellType: ZonarUITableCellType.Text,
      cell: (p: PassholderForTable) => p.group_name
    },
    {
      columnDef: 'card_count',
      header: PASSHOLDER_COLUMN_HEADERS.CARD_COUNT,
      sortable: false,
      cellType: ZonarUITableCellType.Text,
      cell: (p: PassholderForTable) => p.card_count.toString()
    },
    {
      columnDef: 'last_updated',
      header: PASSHOLDER_COLUMN_HEADERS.LAST_UPDATED,
      sortable: false,
      type: ZonarUITableCellType.Text,
      cellType: ZonarUITableCellType.Icon,
      cell: (p: PassholderForTable) => {
        const dateString = this.setDateString(p.last_updated_ts);
        return dateString;
      }
    }
  ];
  private customColumnsForTable$ = new BehaviorSubject<ZonarUITableModel[]>([]);
  paginationParams$ = this.paginationParamsSubject.asObservable();
  loading$ = this.tableLoading.asObservable();
  total$ = this.totalResults.asObservable();
  errorMsg$ = this.errorMessage.asObservable();
  data: PassholderForTable[] = [];
  defaultPagination = true;
  isMobile = true;
  offset: number;
  currentPage: number;
  resetPaging$ = new BehaviorSubject<boolean>(false);
  deactivatedPassholders$: BehaviorSubject<{}> = new BehaviorSubject(null);
  pageSize =
    this.getEnvService.getEnvironmentProperty('paginationSettings')['pageSize'];

  mobileFiltersChanged() {
    this.resetPaging$.next(true);
    this.paginationParamsSubject.next({ page: 1, per_page: 10 });
  }

  loadData(params?: ZpxFrontendPageParams): void {
    this.tableLoading.next(true);
    this.paginationParamsSubject.next(params);
  }

  getStandardColumnTableModels(): ZonarUITableModel[] {
    return this.standardColumns;
  }

  getCustomColumnTableModels$(): BehaviorSubject<ZonarUITableModel[]> {
    return this.customColumnsForTable$;
  }

  setDateString(date: Date): string {
    return formatDate(date, 'YYYY/MM/DD');
  }

  getPassData(passholders: Passholder[]): PassholderForTable[] {
    const passesForTable = [];
    if (passholders?.length) {
      passholders.forEach((passholder) => {
        const custom_columns = passholder?.custom_columns;
        const placeholderPass = {
          number: null,
          active: null
        };
        let card_count = 0;
        let mostRecentPass = null;
        let passes = [];
        if (passholder.passes) {
          passes = Object.keys(passholder.passes).map((key) => {
            return {
              ...passholder.passes[key],
              insert_ts: new Date(passholder.passes[key].insert_ts)
            };
          });
          card_count = passes.length;
          mostRecentPass = getMostRecentPass(passes);
        } else {
          mostRecentPass = placeholderPass;
        }

        let passForTable = {
          card_number: mostRecentPass.number,
          status: passholder.active ? STATUSES.ACTIVE : STATUSES.INACTIVE,
          last_name: passholder.last_name,
          first_name: passholder.first_name,
          unique_id: passholder.exsid,
          group_name: passholder.group_name,
          card_count,
          other_cards: passes,
          last_updated_ts: new Date(passholder.last_updated_ts),
          active: passholder.active,
          zpx_id: passholder.id
        };
        if (custom_columns) {
          Object.keys(custom_columns)
            .map((key) => {
              return {
                ...custom_columns[key]
              };
            })
            .sort((a, b) => a.sequence - b.sequence)
            .forEach((c) => {
              passForTable = {
                ...passForTable,
                [c.name]: c.value
              };
            });
        }
        passesForTable.push(passForTable);
      });
    }
    return passesForTable;
  }

  getEditColumnTableModel(): ZonarUITableModel {
    return {
      columnDef: 'edit',
      header: null,
      sortable: false,
      type: ZonarUITableCellType.Icon,
      cellType: ZonarUITableCellType.Icon,
      cell: () => 'edit'
    };
  }

  customColumnsToTableModel(customTypeColumns: any[]): ZonarUITableModel[] {
    if (customTypeColumns?.length) {
      return customTypeColumns.map((c) => {
        return {
          columnDef: c.name,
          header: c.name,
          sortable: false,
          cellType: ZonarUITableCellType.Text,
          cell: (p: PassholderForTable) => p[c.name] || null
        };
      });
    }
    return [];
  }

  shouldLoadData(): Observable<boolean> {
    return combineLatest([
      this.divisionsService.isSingleDivisionUser(),
      this.appService.selectedDivisionId$.pipe(distinctUntilChanged())
    ]).pipe(
      map(([isSingleUser, divisionId]) => {
        if (isSingleUser) {
          return true;
        }

        if (divisionId) {
          return true;
        }

        return false;
      })
    );
  }

  populatePassholdersTable(passholderType: PASSHOLDER_TYPES) {
    const paramsObs$ = this.paginationParams$
      .pipe(filter((p) => Boolean(p?.page)))
      .pipe(
        map((paginationParams) =>
          this.setPagingParams(
            paginationParams,
            {
              passholder_type_string: passholderType
            },
            this.totalResults.value // we dont want this to listen for the observable otherwise it reruns the flow every time a new value is set ie when a response comes in
          )
        )
      );
    const filteredPassholdersObs$ = paramsObs$.pipe(
      switchMap((params) =>
        this.filterBarService.filterBody$.pipe(
          tap(() => this.tableLoading.next(true)),
          switchMap((body) => this.zpxApiService.getPassholders(params, body)),
          catchError((error) => {
            return throwError(error);
          })
        )
      )
    );

    const tableDataObs$ = combineLatest([
      filteredPassholdersObs$,
      this.zpxApiService.getCustomColumns().pipe(
        catchError((error) => {
          return throwError(error);
        })
      ),
      this.appService.patchedPassholder$
    ]).pipe(
      map((results) => {
        const [passholders, customCols, patchedPassholder] = results as [
          PassholdersReportHttpResponseBody,
          CustomTypeColumn[],
          Passholder
        ];
        if (patchedPassholder) {
          this.resetPassholdersAfterPatch(
            passholders.data,
            patchedPassholder,
            patchedPassholder.passholder_type_id
          );
        }
        this.data = this.getPassData(passholders.data);
        this.customColumnsForTable$.next(
          this.customColumnsToTableModel(customCols)
        );

        this.totalResults.next(passholders.total_count);
        this.tableDataSubject$.next(this.data);
        this.tableLoading.next(false);
      }),
      catchError((error) => {
        this.errorMessage.next(error);
        return of(undefined);
      })
    );
    return tableDataObs$;
  }

  connect(collectionViewer: CollectionViewer): Observable<any[]> {
    this.resetPassholdersAfterDeactivate();
    return this.tableDataSubject$.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.tableLoading.complete();
    this.tableDataSubject$.complete();
  }

  onTableDestroy(): void {
    this.tableDataSubject$.complete();
    this.tableLoading.complete();
  }

  // takes out inactive users from table after they are deactivated
  // still buggy as a refresh will still show inactive user when "active" filter is on, this bug to be addressed in https://zonarsystems.atlassian.net/browse/PUP-5040
  resetPassholdersAfterDeactivate() {
    this.appService.deactivatedPassholders$
      .pipe(filter((p) => p !== null))
      .subscribe((deactivatedPassholders) => {
        this.data = this.data.filter((passholder) => {
          if (deactivatedPassholders[passholder.zpx_id]) {
            return false;
          }
          return true;
        });
        this.tableDataSubject$.next(this.data);
      });
  }

  resetPassholdersAfterPatch(
    passholderData: Passholder[],
    patchedPassholder: Passholder,
    passholderTypeId: string
  ) {
    const isPassholderTypeChanged =
      patchedPassholder.passholder_type_id &&
      patchedPassholder.passholder_type_id !== passholderTypeId;

    for (let i = 0; i < passholderData.length; i++) {
      const p = passholderData[i];

      if (p.id === patchedPassholder.id) {
        if (isPassholderTypeChanged) {
          passholderData.splice(i, 1);
        } else {
          passholderData[i] = patchedPassholder;
        }

        break;
      }
    }
  }

  // repeated code, wrote ticket here for future improvements https://zonarsystems.atlassian.net/browse/PUP-5170
  setPagingParams(
    feParams: ZpxFrontendPageParams,
    beParams: ZpxApiPassholderParams,
    total: number
  ): ZpxApiPassholderParams {
    if (feParams.page === 1) {
      this.offset = 0;
    } else {
      if (feParams.page > this.currentPage) {
        const lastPage = Math.floor(total / feParams.per_page);

        let isLastPage: boolean;
        // if there are additional riders beyond the lastPage floor, account for them
        if (total > lastPage * feParams.per_page) {
          // + 1 to lastPage to account for non-array page numbering, e.g. a lastPage of 10 would be 11 for frontend
          isLastPage = lastPage + 1 === feParams.page;
        } else {
          // otherwise no need for the extra page when evaluating isLastPage
          isLastPage = lastPage === feParams.page;
        }

        if (isLastPage) {
          if (total > lastPage * feParams.per_page) {
            this.offset = total - feParams.per_page;
          } else {
            // accounts for the case when we only have 2 pages max
            this.offset += feParams.per_page;
          }
        } else {
          // prevent setting an offset that is greater than data array length
          // will never actually occur in the UI, but put this safeguard in for edgecase testing purposes
          const nextOffset = this.offset + feParams.per_page;
          this.offset = nextOffset < total ? nextOffset : this.offset;
        }
      } else {
        // prevent setting offset to be a negative number
        // will never actually occur in the UI, but put this safeguard in for edgecase testing purposes
        const nextOffset = this.offset - feParams.per_page;
        this.offset = nextOffset >= 0 ? nextOffset : 0;
      }
    }

    this.currentPage = feParams.page;

    const newPagingParams = {
      ...beParams,
      offset: this.offset,
      limit: feParams.per_page
    };

    return newPagingParams;
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import {
  CustomTypeColumn,
  Group,
  Passholder,
  PASSHOLDER_TYPES,
  PassholderForTable,
  PassholderType
} from './models/zpx-api.model';
import { LegacyLocation } from './models/entity-api.model';

/*

This is the highest level service that is used to share top level information via Behavior Subjects across the app for information that any component or other service needs without relying on deeply nested inputs/outputs or sending Behavior subjects from weird places

For example, the first member for this service is passholderType$  to share the passholer type across the app as different places need to know the type in order to fetch items specific to that type. Please use this service accordingly for other top level information.

*/
@Injectable({
  providedIn: 'root'
})
export class AppService {
  passholderType$: BehaviorSubject<PASSHOLDER_TYPES> = new BehaviorSubject(
    null
  );

  passholderTypeId$: BehaviorSubject<string> = new BehaviorSubject(null);

  customColumns$: BehaviorSubject<CustomTypeColumn[]> = new BehaviorSubject(
    null
  );

  passholders$: BehaviorSubject<PassholderForTable[]> = new BehaviorSubject(
    null
  );

  passholderTypes$: BehaviorSubject<PassholderType[]> = new BehaviorSubject(
    null
  );

  passholderTableLoading$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  groupsByNameAsc$: BehaviorSubject<Group[]> = new BehaviorSubject(null);

  patchedPassholder$: BehaviorSubject<Passholder> = new BehaviorSubject(null);

  selectedDivisionId$: BehaviorSubject<string> = new BehaviorSubject(null);

  divisions$: BehaviorSubject<{ name: string; id: string }[]> =
    new BehaviorSubject(null);

  legacyLocations$: BehaviorSubject<LegacyLocation[]> = new BehaviorSubject(
    null
  );

  deactivatedPassholders$: BehaviorSubject<any> = new BehaviorSubject(null);

  isMobile$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  selectedGroup$: BehaviorSubject<Group> = new BehaviorSubject(null);

  assets$: BehaviorSubject<
    {
      name: string;
      id: string;
      [name: string]: string | string[];
    }[]
  > = new BehaviorSubject(null);

  constructor() {}
}

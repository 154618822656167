import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  from,
  Observable,
  of,
  throwError
} from 'rxjs';
import { CollectionViewer } from '@angular/cdk/collections';
import {
  ZonarUITableDataSource,
  ZonarUITableModel,
  ZonarUITableCellType
} from '@zonar-ui/table';
import { Params } from '@angular/router';
import { ZpxApiService } from '@src/app/services/zpx-api-service/zpx-api.service';
import {
  EVENT_COLUMN_HEADERS,
  ZpxFrontendPageParams
} from '@src/app/models/zpx-api.model';
import { GetEnvironmentService } from '@src/app/services/get-environment/get-environment.service';
import { formatDate } from '@src/app/shared/utilities/utilities';
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  tap
} from 'rxjs/operators';
import { GetZoneNameService } from '@src/app/services/get-zone-name/get-zone-name.service';
import { SelectedCompanyService } from '@src/app/services/selected-company/selected-company.service';
import { EventsFilterBarService } from '../services/events-filter-bar.service';
import {
  ZpxEventForTable,
  ZpxEvent,
  ZpxApiEventGetReportBodyParams
} from '../models/events.model';
import { AppService } from '@src/app/app.service';

@Injectable()
export class EventsTableDataSource implements ZonarUITableDataSource {
  constructor(
    private zpxApiService: ZpxApiService,
    private getEnvService: GetEnvironmentService,
    private zoneService: GetZoneNameService,
    private selectedCompanyService: SelectedCompanyService,
    private eventsFilterBarService: EventsFilterBarService,
    private appService: AppService
  ) {}

  private tableLoading = new BehaviorSubject<boolean>(false);
  private errorMessage = new BehaviorSubject<string>('');
  private totalResults = new BehaviorSubject<number>(0);
  private tableDataSubject$ = new BehaviorSubject<any[]>([]);
  private paginationParamsSubject = new BehaviorSubject<any>({});
  eventsZoneId$ = new BehaviorSubject<{ id: string; index: number }>(null);
  paginationParams$ = this.paginationParamsSubject.asObservable();
  loading$ = this.tableLoading.asObservable();
  total$ = this.totalResults.asObservable();
  errorMsg$ = this.errorMessage.asObservable();
  data: ZpxEventForTable[] = [];
  defaultPagination = true;
  offset: number = 0;
  currentPage: number = 1;
  passholderTypesMap = null;
  selectedCompanyId = null;

  private standardColumns: ZonarUITableModel[] = [
    {
      columnDef: 'last_name',
      header: EVENT_COLUMN_HEADERS.LAST_NAME,
      sortable: false,
      cellType: ZonarUITableCellType.Text,
      cell: (e: ZpxEventForTable) => e.last_name
    },
    {
      columnDef: 'first_name',
      header: EVENT_COLUMN_HEADERS.FIRST_NAME,
      sortable: false,
      cellType: ZonarUITableCellType.Text,
      cell: (e: ZpxEventForTable) => e.first_name
    },
    {
      columnDef: 'card_number',
      header: EVENT_COLUMN_HEADERS.CARD_NUMBER,
      sortable: false,
      cellType: ZonarUITableCellType.Text,
      cell: (e: ZpxEventForTable) => e.card_number
    },

    {
      columnDef: 'ch_type',
      header: EVENT_COLUMN_HEADERS.CH_TYPE,
      sortable: false,
      cellType: ZonarUITableCellType.Text,
      cell: (e: ZpxEventForTable) => e.ch_type
    },
    {
      columnDef: 'unique_id',
      header: EVENT_COLUMN_HEADERS.UNIQUE_ID,
      sortable: false,
      cellType: ZonarUITableCellType.Text,
      cell: (e: ZpxEventForTable) => e.unique_id
    },
    {
      columnDef: 'asset_no',
      header: EVENT_COLUMN_HEADERS.ASSET_NO,
      sortable: false,
      cellType: ZonarUITableCellType.Text,
      cell: (e: ZpxEventForTable) => e.asset_no
    },
    {
      columnDef: 'zone',
      header: EVENT_COLUMN_HEADERS.ZONE,
      sortable: false,
      cellType: ZonarUITableCellType.Text,
      cell: (e: ZpxEventForTable) => e.zone
    },
    {
      columnDef: 'group',
      header: EVENT_COLUMN_HEADERS.GROUP,
      sortable: false,
      cellType: ZonarUITableCellType.Text,
      cell: (e: ZpxEventForTable) => e.group
    },
    {
      columnDef: 'date',
      header: EVENT_COLUMN_HEADERS.DATE,
      sortable: false,
      cellType: ZonarUITableCellType.Text,
      cell: (e: ZpxEventForTable) => e.date
    },
    {
      columnDef: 'event_type',
      header: EVENT_COLUMN_HEADERS.EVENT_TYPE,
      sortable: false,
      cellType: ZonarUITableCellType.Text,
      cell: (e: ZpxEventForTable) => e.event_type
    },
    {
      columnDef: 'chevron_right',
      header: null,
      sortable: false,
      type: ZonarUITableCellType.Icon,
      cellType: ZonarUITableCellType.Icon,
      cell: () => 'chevron_right'
    }
  ];

  pageSize =
    this.getEnvService.getEnvironmentProperty('paginationSettings')['pageSize'];

  loadData(params?: Params): void {
    this.paginationParamsSubject.next(params);
  }

  getStandardColumns(showCheckmarks = false): ZonarUITableModel[] {
    if (showCheckmarks && this.standardColumns[0].columnDef !== 'checkmark') {
      return [this.getCheckmarkColumn(), ...this.standardColumns];
    }
    return this.standardColumns;
  }

  getCheckmarkColumn(): ZonarUITableModel {
    return {
      columnDef: 'checkmark',
      header: null,
      sortable: false,
      type: ZonarUITableCellType.Checkbox,
      cellType: ZonarUITableCellType.Checkbox,
      checkboxDisabled: () => false,
      cell: (e) => e
    };
  }

  formatEventsForTable(events: ZpxEvent[]): ZpxEventForTable[] {
    if (events?.length) {
      return events.map((e) => {
        return {
          last_name: e.ph_last_name || null,
          first_name: e.ph_first_name || null,
          card_number: e.p_number || null,
          ch_type: e.ph_type_id || null,
          zone: e.zone || null,
          asset_no: e.asset_name || null,
          group: e.ph_group_name || null,
          unique_id: e.ph_exsid || null,
          date: formatDate(new Date(e.event_ts)) || null,
          event_type: e?.event_flags?.join(',') || null
        };
      });
    }
    return [];
  }

  getPassholderTypesMap() {
    if (this.passholderTypesMap === null) {
      return this.appService.passholderTypes$.pipe(
        filter((response) => response !== null),
        map((response) => {
          this.passholderTypesMap = response.reduce((acc, curr) => {
            acc[curr.id] = curr.name;
            return acc;
          }, {});
          return this.passholderTypesMap;
        })
      );
    }

    return of(this.passholderTypesMap);
  }

  populateEventsTable() {
    const companyObs$ = this.selectedCompanyService.getCompanyId().pipe(
      filter(
        (companyId) =>
          companyId !== null && this.selectedCompanyId !== companyId
      ),
      tap((companyId) => {
        // wipe out current event data if new company switch, known buggy behavior documented in https://zonarsystems.atlassian.net/browse/PUP-5171
        this.tableLoading.next(true);
        this.tableDataSubject$.next([]);
        this.selectedCompanyId = companyId;
      })
    );

    const paramsObs$ = this.paginationParams$.pipe(
      filter((p) => Boolean(p?.page)),
      map((paginationParams) => {
        return this.setPagingParams(
          paginationParams,
          { include_ignored: false },
          this.totalResults.value // we dont want this to listen for the observable otherwise it reruns the flow every time a new value is set ie when a response comes in
        );
      })
    );

    const paginationAndFilter$ = combineLatest([
      paramsObs$,
      this.eventsFilterBarService.filterBody$
    ]);

    const eventsObs$ = paginationAndFilter$.pipe(
      tap(() => this.tableLoading.next(true)),
      mergeMap(([pageParams, filterBody]) => {
        let params = pageParams;
        if (filterBody) {
          params = {
            ...pageParams,
            ...filterBody
          };
        }
        return this.zpxApiService.getEventsReport(params);
      }),
      catchError((error) => {
        return throwError(error);
      })
    );

    const eventsAndPassholderTypes$ = combineLatest([
      companyObs$,
      eventsObs$,
      this.getPassholderTypesMap()
    ]) as Observable<any>;

    return eventsAndPassholderTypes$.pipe(
      tap(([_, events, passholderTypes]) => {
        this.data = this.formatEventsForTable(events.data);

        if (!this.data.length) {
          this.tableDataSubject$.next(this.data);
          this.tableLoading.next(false);
          this.totalResults.next(events.total_count);
          return;
        }

        //  set passholder type name from incoming map
        this.data.forEach((event) => {
          event.ch_type = passholderTypes[event.ch_type];
        });

        this.totalResults.next(events.total_count);
      }),
      map((_) => {
        // get array of non-duplicated zone ids so we don't make repetitive calls to Zone API
        return [...new Set(this.data.map((e) => e.zone))];
      }),
      switchMap((zoneIds) => {
        // pass in zone ids to get the zone name
        return this.getZoneNames(zoneIds);
      })
    );
  }

  getZoneNames(zoneIds: string[]): Observable<any> {
    // is possible in the case of data have no zone ids
    if (zoneIds.length === 1 && zoneIds[0] === null) {
      this.tableDataSubject$.next(this.data);
      this.tableLoading.next(false);
      return of(null);
    }
    return from(zoneIds).pipe(
      mergeMap((zoneId) => {
        return this.zoneService.getZoneName(zoneId).pipe(
          tap((zoneName) => {
            this.data.forEach((event) => {
              if (event.zone === zoneId) {
                event.zone = zoneName;
              }
            });

            this.tableDataSubject$.next(this.data);
            this.tableLoading.next(false);
          }),
          catchError((err) => {
            // still render data if we fail at fetching zone name
            this.tableDataSubject$.next(this.data);
            this.tableLoading.next(false);
            // just return null so we can gracefully swallow error without breaking the page in case of failed zone call, using a throwError here breaks pagination
            return of(null);
          })
        );
      })
    );
  }

  connect(collectionViewer: CollectionViewer): Observable<any[]> {
    return this.tableDataSubject$.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.tableLoading.complete();
    this.tableDataSubject$.complete();
  }

  onTableDestroy(): void {
    this.tableDataSubject$.complete();
    this.tableLoading.complete();
  }

  // repeated code, wrote ticket here for future improvements https://zonarsystems.atlassian.net/browse/PUP-5170
  setPagingParams(
    feParams: ZpxFrontendPageParams,
    beParams: ZpxApiEventGetReportBodyParams,
    total: number
  ): ZpxApiEventGetReportBodyParams {
    if (feParams.page === 1) {
      this.offset = 0;
    } else {
      if (feParams.page > this.currentPage) {
        const lastPage = Math.floor(total / feParams.per_page);

        let isLastPage: boolean;
        // if there are additional riders beyond the lastPage floor, account for them
        if (total > lastPage * feParams.per_page) {
          // + 1 to lastPage to account for non-array page numbering, e.g. a lastPage of 10 would be 11 for frontend
          isLastPage = lastPage + 1 === feParams.page;
        } else {
          // otherwise no need for the extra page when evaluating isLastPage
          isLastPage = lastPage === feParams.page;
        }

        if (isLastPage) {
          if (total > lastPage * feParams.per_page) {
            this.offset = total - feParams.per_page;
          } else {
            // accounts for the case when we only have 2 pages max
            this.offset += feParams.per_page;
          }
        } else {
          // prevent setting an offset that is greater than data array length
          // will never actually occur in the UI, but put this safeguard in for edgecase testing purposes
          const nextOffset = this.offset + feParams.per_page;
          this.offset = nextOffset < total ? nextOffset : this.offset;
        }
      } else {
        // prevent setting offset to be a negative number
        // will never actually occur in the UI, but put this safeguard in for edgecase testing purposes
        const nextOffset = this.offset - feParams.per_page;
        this.offset = nextOffset >= 0 ? nextOffset : 0;
      }
    }

    this.currentPage = feParams.page;

    const newPagingParams = {
      ...beParams,
      offset: this.offset,
      limit: feParams.per_page
    };

    return newPagingParams;
  }
}

/* eslint-disable @angular-eslint/no-conflicting-lifecycle */
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  DoCheck
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { PASSHOLDER_TYPES } from '@models/zpx-api.model';
import { FormGroup } from '@angular/forms';

import { map, takeUntil } from 'rxjs/operators';

import { AppService } from '@app/app.service';
import { Subject } from 'rxjs';
import {
  SearchableDropdownModel,
  ZuiFiltersBarComponent
} from '@zonar-ui/filter';
import _ from 'lodash';
import { EventsFilterBarService } from '../services/events-filter-bar.service';
import { GetDivisionsService } from '@services/get-divisions/get-divisions.service';
import { ZuiDatePickerModule } from '@zonar-ui/date-picker';
import {
  RangeParams,
  ZpxApiEventGetReportBodyParams,
  ZPX_EVENT_PARAM_NAMES
} from '../models/events.model';

@Component({
  selector: 'app-events-filter-bar',
  standalone: true,
  imports: [CommonModule, ZuiFiltersBarComponent, ZuiDatePickerModule],
  templateUrl: './events-filter-bar.component.html',
  styleUrls: ['./events-filter-bar.component.scss']
})
// eslint-disable-next-line @angular-eslint/no-conflicting-lifecycle
export class EventsFilterBarComponent implements OnInit, OnChanges, DoCheck {
  constructor(
    public filterBarService: EventsFilterBarService,
    public appService: AppService,
    private divisionsService: GetDivisionsService
  ) {}
  @Output() mobileDisplayed = new EventEmitter();
  @Input() isMobile = false;

  @Input() toggleDisplay: boolean;
  private unsubscribe$ = new Subject<void>();
  passholderType = PASSHOLDER_TYPES.STUDENT;
  filterList: SearchableDropdownModel[];
  filterFormGroup = new FormGroup({});
  shouldSendDivisionId: boolean = null;
  changedEvent: any = null;
  selectedDivisionId: string = null;
  selectedRange: RangeParams = null;

  mobileViewClasses = {
    'mobile-view': this.isMobile ? true : false,
    hidden: false
  };
  removeDivisionFilter() {
    const first: SearchableDropdownModel = this.filterList[0];
    if (first.options.fgControlName === ZPX_EVENT_PARAM_NAMES.DIVISION_ID) {
      this.filterList.shift();
    }
  }
  filterChanged(e) {
    if (this.changedEvent === null) this.changedEvent = e;

    // for some reason, the zui filters bar doesn't enable the date picker to be part of the emitting form group, so we have to capture it separately and add it later to filter params at bottom of this method, see "selectedFilterObj"
    if (
      e[ZPX_EVENT_PARAM_NAMES.START_TIME] &&
      e[ZPX_EVENT_PARAM_NAMES.END_TIME]
    ) {
      this.selectedRange = {
        [ZPX_EVENT_PARAM_NAMES.START_TIME]: e[ZPX_EVENT_PARAM_NAMES.START_TIME],
        [ZPX_EVENT_PARAM_NAMES.END_TIME]: e[ZPX_EVENT_PARAM_NAMES.END_TIME]
      };
    }
    if (!_.isEqual(this.changedEvent, e)) {
      this.appService.passholderTableLoading$.next(true);
      this.changedEvent = e;

      this.shouldSendDivisionId =
        Boolean(e?.division_id?.selected) && Object.keys(e).length === 1;

      if (this.shouldSendDivisionId) {
        const incomingDivisionId = e.division_id.selected;

        // saving of selectedDivisionId prevents emissions of the same division id and prevents redundant api calls
        if (this.selectedDivisionId !== incomingDivisionId) {
          this.selectedDivisionId = incomingDivisionId;
          this.appService.selectedDivisionId$.next(this.selectedDivisionId);
        }
      } else {
        // We're not switching division IDs, so the filter values need to be acted upon.
        // We need to do this little bit because divisionId is a string, and we also only
        // care about the state of the filters that have been selected
        const filterFormState: ZpxApiEventGetReportBodyParams =
          this.filterFormGroup.value;
        const selectedFilterObj: ZpxApiEventGetReportBodyParams = {};
        Object.keys(filterFormState)
          .filter(
            (ff) =>
              Array.isArray(filterFormState[ff]) &&
              filterFormState[ff].length > 0
          )
          .forEach(
            (selectedFilter) =>
              (selectedFilterObj[selectedFilter] =
                filterFormState[selectedFilter])
          );

        if (this.selectedRange) {
          selectedFilterObj.from_date =
            this.selectedRange[ZPX_EVENT_PARAM_NAMES.START_TIME];

          selectedFilterObj.to_date =
            this.selectedRange[ZPX_EVENT_PARAM_NAMES.END_TIME];
        }

        // since includde_ignored is not an array, it gets filtered out from above processes, so manually add it here
        if (
          filterFormState.hasOwnProperty(ZPX_EVENT_PARAM_NAMES.INCLUDE_IGNORED)
        ) {
          const ignored =
            filterFormState[ZPX_EVENT_PARAM_NAMES.INCLUDE_IGNORED];

          // it's possible for undefined string '' to be sent in, so we do this check here to pass only if boolean
          if (typeof ignored === 'boolean') {
            selectedFilterObj[ZPX_EVENT_PARAM_NAMES.INCLUDE_IGNORED] = ignored;
          }
        }

        this.filterBarService.eventFilterChanged$.next(true);
        this.filterBarService.parseUserSelectedFilters(selectedFilterObj);
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isMobile'] !== undefined) {
      // ensure that filter bar is hidden the first time mobile view is entered (either on init, or when dynamically resizing the page)
      this.mobileViewClasses.hidden = changes['isMobile'].currentValue === true;
      this.mobileViewClasses['mobile-view'] = changes['isMobile'].currentValue;
      return;
    }
    if (changes['toggleDisplay'] !== undefined) {
      this.mobileViewClasses.hidden = !this.mobileViewClasses.hidden;

      this.mobileDisplayed.emit(!this.mobileViewClasses.hidden);
    }
  }

  ngDoCheck() {
    // a dumb hack needed to set include ignored to false by default since setting it in events filter bar service did not work and we need some time fo detect if the form exist so it does not work in ngOnInit due to async issues¯\_(ツ)_/¯
    const ignoredForm =
      this.filterFormGroup.controls[ZPX_EVENT_PARAM_NAMES.INCLUDE_IGNORED];
    if (ignoredForm) {
      if (typeof ignoredForm.value !== 'boolean') {
        ignoredForm.setValue(false);
      }
    }
  }
  ngOnInit() {
    this.appService.selectedDivisionId$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((id) => {
        this.selectedDivisionId = id;
      });

    this.filterList = this.filterBarService.getCommonColumnsFilters();

    this.filterBarService.setCommonColumnFiltersOptions(this.passholderType);

    this.divisionsService
      .isSingleDivisionUser()
      .pipe(
        map((isSingleDivisionUser) => {
          if (isSingleDivisionUser) {
            this.removeDivisionFilter();
          }
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }
}
